










































import Vue from 'vue'
import { mapState } from 'vuex'
import { Component } from 'vue-property-decorator'
import { Subject } from '@/store/subjects'

@Component({
  computed: mapState(['currentSet', 'isDarkMode']),
})
export default class Calculator extends Vue {
  public currentSet!: string
  public isDarkMode!: boolean
  public clearedGrades: boolean = false

  public onClearClicked() {
    this.$store.dispatch('clearGrades').then(() => this.clearedGrades = true)
  }

  get average(): number {
    let total = 0
    let totalUnits = 0

    // Get weighted sum
    this.currentSubjects.forEach((subject: Subject, i: number) => {
      totalUnits += subject.units
      total += this.grades[i] * subject.units
    })

    return total / totalUnits
  }
  get grades(): number[] {
    return this.$store.getters.grades
  }
  get currentSubjects(): Subject[] {
    return this.$store.getters.currentSetSubjects
  }
}
